import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './appReducer';

// Preload state function
const preloadState = () => {
    const localState = localStorage.getItem('localState');
    return {
        local: localState ? JSON.parse(localState) : undefined,
    };
};

// Middleware to handle local storage updates
const localStorageMiddleware = storeAPI => next => action => {
    const result = next(action);
    const state = storeAPI.getState();
    localStorage.setItem('localState', JSON.stringify(state.local));
    return result;
};

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {},
        serializableCheck: false,
      }).concat(localStorageMiddleware),
    preloadedState: preloadState(),
});