import { combineReducers } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import localReducer from './localSlice';
import temporaryReducer from './temporarySlice';

// Create a reset action
export const resetAppState = createAction('resetAppState');

// Combine reducers
const appReducer = combineReducers({
  local: localReducer,
  temporary: temporaryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetAppState.type) {
    // Clear the local storage
    localStorage.clear();
    // Reset the state by passing undefined
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;