import './style/CollapsableSection.css';
import React, {useState} from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';

const CollapsableSection = ({ label, content, collapsed = true }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const handleClick = () => {
        // Implement the collapse/expand functionality
        setIsCollapsed(!isCollapsed);
    }
    return (
        <div className="product-vertical-container flex-1 v-gap-s row-clickable secondary pad-s" onClick={handleClick}>
            <Label content={label} size="s" bold={true} displayFont={true} secondary={!isCollapsed ? true : false} />
            {!isCollapsed &&  content}
        </div>
    );
};

export default CollapsableSection;