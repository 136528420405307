import './style/LoaderBlock.css';
import React from 'react';

const LoaderBlock = ({ loading }) => {
    return (
        <div className="keystone-loader-block">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
    );
};

export default LoaderBlock;