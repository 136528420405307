import './style/Row.css';
import React, { useState, useRef, useEffect } from 'react';
import Button from './Button';
import InfoBlock from './InfoBlock';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const Row = (
    {
        index,
        primaryLabel,
        primaryMono = false,
        secondaryLabel,
        secondaryLabelSingleLine,
        onClick = null,
        primaryLabelAlternate = null,
        secondaryLabelAlternate = null,
        hidden = false,
        tag = {
            label: "",
            secondary: true,
            format: "text"
        },
        tags,
        button = {
            icon: null,
            label: "",
            labelAlternate: null,
            loading: false,
            brand: false,
            onClick: null
        },
        infoBlocks = [],
        tooltip = false,
        tooltipActions = null,
        showAlternate = false,
        lastColumnContent = null,
        table = false,
        navigateOnClick = false,
        page = null,
        tab = null,
        secondary
    }
) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const tooltipRef = useRef(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const keystoneInterface = useSelector((state) => state.keystoneInterface);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !buttonRef.current.contains(event.target) && tooltipVisible) {
                setTooltipVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [buttonRef, tooltipVisible]);

    // TODO: Evan, this is fucking stupid. Fix it.
    useEffect(() => {
    }, [infoBlocks]);

    const handleTooltipOptionClick = (event) => {
        event.stopPropagation();
        setTooltipVisible(!tooltipVisible);

        if (button.onClick) {
            button.onClick();
        }
    };

    const handleOnClick = () => {
        const params = new URLSearchParams();
        params.set('id', index);
        navigate(`${page ? `/${page}` : pathname}${tab ? `/${tab}` : ""}?${params.toString()}`);
    };

    return (
        <div key={index} className={`keystone-row product-horizontal-container space-between align-center ${table ? "table-row" : secondary ? "keystone-card-secondary" : "keystone-card"} pad-m ${(onClick !== null || navigateOnClick) ? "clickable" : ""} ${index === keystoneInterface?.selectedObjectId ? "selected" : ""}`} onClick={navigateOnClick ? handleOnClick : onClick}>
            <div className={`product-horizontal-container flex-1 v-gap-s space-between align-center ${table ? "" : "h-gap-xxl"}`}>
                <div className={`product-horizontal-container align-center flex-1 ${table ? "" : "h-gap-l space-between"}`}>
                    <InfoBlock
                        showAlternate={showAlternate}
                        primaryLabel={primaryLabel}
                        primaryMono={primaryMono}
                        secondaryLabel={secondaryLabel}
                        secondaryLabelSingleLine={secondaryLabelSingleLine}
                        tags={tags}
                        primaryLabelAlternate={primaryLabelAlternate}
                        secondaryLabelAlternate={secondaryLabelAlternate}
                        padRight={true}
                        hidden={hidden}
                        flex={table} />
                    {infoBlocks.length > 0 && infoBlocks.map((block, index) => (
                        <InfoBlock
                            key={index}
                            index={index}
                            primaryLabel={block.primaryLabel}
                            primaryFormat={block.primaryFormat}
                            primaryMono={block.primaryMono}
                            secondaryLabel={block.secondaryLabel}
                            secondaryLabelSingleLine={block.secondaryLabelSingleLine}
                            flex={block.flex}
                            tags={block.tags}
                            processing={block.processing}
                            alignRight={block.alignRight}
                            padRight={block.padRight}
                            padLeft={block.padLeft}
                            hidden={block.hidden}
                            hiddenOnMobile={true} />
                    ))}
                    {lastColumnContent && lastColumnContent}
                </div>
                {(button.icon || button.label?.length > 0) && (
                    <Button icon={button.icon}
                        iconSize={24}
                        label={showAlternate ? button.labelAlternate : button.label}
                        onClick={(event) => handleTooltipOptionClick(event)}
                        showTooltipContent={tooltipVisible}
                        reference={buttonRef}
                        brand={button.brand}
                        loading={button.loading}
                        showAlternate={showAlternate}
                        tooltipContent={tooltip && tooltipActions && (
                            <div className="ks-menu-content product-vertical-container v-gap-s" ref={tooltipRef}>
                                {tooltipActions}
                            </div>
                        )} />
                )}
            </div>
        </div>
    );
};

export default Row;