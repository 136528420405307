import { createSlice } from '@reduxjs/toolkit';

export const localSlice = createSlice({
    name: 'local',
    initialState: {
        cart: [],
    },
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
        },
    },
});

export const { setCart } = localSlice.actions;

export default localSlice.reducer;