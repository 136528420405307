import './style/SimpleLoader.css';
import React from 'react';

const SimpleLoader = ({ loading, size = 32 }) => {
    return (
        <div
            className="keystone-simple-loader-wrapper"
            style={{
                opacity: loading ? 1 : 0,
                width: size,
                height: size,
                borderWidth: Math.floor(size / 10),
                left: `calc(50% - ${size / 2}px)`,
                top: `calc(50% - ${size / 2}px)`,
                padding: Math.floor(size / 6),
            }}>
            <div
                className="keystone-simple-loader"
                style={{
                    borderWidth: Math.floor(size / 10),
                }}
            ></div>
        </div>
    );
};

export default SimpleLoader;