import './style/StoreContent.css';
import React,  { useState, useEffect } from 'react';
import Label from '../block/Label';
import Row from '../block/Row';
import { formatToCurrency } from '../../util/Calculate';
import { useDispatch } from 'react-redux';
import Button from '../block/Button';
import Divider from '../block/Divider';
import Input from '../block/Input';
import ChipRounded from '../block/ChipRounded';
import ReCAPTCHA from "react-google-recaptcha";
import { submitOffer } from '../../redux/temporarySlice';
import { setCart } from '../../redux/localSlice';
import SimpleLoader from '../block/SimpleLoader';
import { useSelector } from 'react-redux';

const Cart = (
    { 
        items,
        handleViewProduct,
        setShowSuccessToast
    }
) => {
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const dispatch = useDispatch();
    const temporary = useSelector((state) => state.temporary);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [awaitingSubmittedOffer, setAwaitingSubmittedOffer] = useState(false);
    const [errorSubmittingOffer, setErrorSubmittingOffer] = useState(null);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    const validateFields = () => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    };

    useEffect(() => {
        validateFields();
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    useEffect(() => {
        if (temporary?.submittingOffer) {
            setAwaitingSubmittedOffer(true);
        }
    }, [temporary?.submittingOffer]);

    useEffect(() => {
        if (temporary?.errorSubmittingOffer !== null && awaitingSubmittedOffer) {
            setErrorSubmittingOffer(temporary.errorSubmittingOffer);
        } else if (temporary?.submittedOffers?.length > 0 && awaitingSubmittedOffer) {
            setEmail("");
            setName("");
            setAddressLine1("");
            setAddressLine2("");
            setCity("");
            setZip("");
            setState("");
            setCountry("");
            setCaptchaResponse("");
            setErrorSubmittingOffer(null);
            setShowSuccessToast(true);
            dispatch(setCart([]));
        }
        setAwaitingSubmittedOffer(false);
    }, [temporary?.errorSubmittingOffer, temporary?.submittedOffers]);

    const handleOrderRequest = () => {
        if (!submitEnabled) {
            return;
        }

        let offer = {
            recaptcha_response: captchaResponse,
            line_items: items.map((cartItem) => {
                return {
                    mpn: cartItem.product.marketMpn,
                    manufacturer: cartItem.product.marketManufacturer,
                    quantity: cartItem.quantity,
                    unit_price: cartItem.product.price,
                    currency: "USD"
                };
            }),
            buyer: {
                email: email,
                shipping_address: {
                    line1: addressLine1,
                    line2: addressLine2,
                    city: city,
                    region: state,
                    zip: zip,
                    country: country
                }
            }
        }

        dispatch(submitOffer({ offer }));
    };

    return (
        <div className="product-vertical-container v-gap-l">
            <div className="product-vertical-container keystone-card overflow-hidden">
                {items?.map((cartItem) => (
                    <Row
                        key={cartItem.product.id}
                        index={cartItem.product.id}
                        primaryLabel={cartItem.product.mpn}
                        secondaryLabel={cartItem.product.manufacturer}
                        onClick={() => handleViewProduct(cartItem.product)}
                        table={true}
                        infoBlocks={[
                            {
                                primaryLabel: "Quantity",
                                secondaryLabel: cartItem.quantity
                            },
                            {
                                primaryLabel: "Unit Price",
                                secondaryLabel: formatToCurrency(cartItem.product.price)
                            },
                            {
                                primaryLabel: "Total",
                                secondaryLabel: formatToCurrency(cartItem.product.price * cartItem.quantity)
                            }
                        ]}
                    />
                ))}
                {items?.length === 0 && (
                    <div className="pad-l">
                        <Label content="Your cart is empty" size="s" bold={true} displayFont={true} center={true} secondary={true} />
                    </div>
                )}
            </div>
            {items?.length > 0 && (
                <div className="product-horizontal-container relative">
                    <div className="product-horizontal-container flex-1 mobile-hide"></div>
                    <div id="order-request" className="product-vertical-container keystone-card-secondary">
                        <div className="product-horizontal-container h-gap-xl pad-m space-between white-bg">
                            <Label content="Order Request" size="m" bold={true} displayFont={true}/>
                        </div>
                        <Divider vertical={false} />
                        <div className="product-vertical-container pad-m no-pad-bot v-gap-s">
                            <Label content="Contact Information" size="s" bold={true} displayFont={true}/>
                            <div className="product-vertical-container v-gap-xs">
                                <Input placeholder="Name" onWhite={false} flex={true} onChange={(e) => setName(e.target.value)} />
                                <Input placeholder="Email" type="email" onWhite={false} flex={true} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="product-vertical-container pad-m v-gap-s">
                            <Label content="Shipping Information" size="s" bold={true} displayFont={true}/>
                            <div className="product-vertical-container v-gap-xs">
                                <Input placeholder="Address line 1" onWhite={false} flex={true} onChange={(e) => setAddressLine1(e.target.value)} />
                                <Input placeholder="Address line 2" onWhite={false} flex={true} onChange={(e) => setAddressLine2(e.target.value)} />
                                <div className="product-horizontal-container flex-1 width-100 h-gap-xs">
                                    <Input placeholder="City" onWhite={false} flex={true} onChange={(e) => setCity(e.target.value)} />
                                    <Input placeholder="Zip" onWhite={false} flex={true} onChange={(e) => setZip(e.target.value)} />
                                </div>
                                <div className="product-horizontal-container flex-1 width-100 h-gap-xs">
                                    <div className="product-horizontal-container flex-1 h-gap-xs">
                                        <Input placeholder="State" onWhite={false} flex={true} onChange={(e) => setState(e.target.value)} />
                                    </div>
                                    <div className="product-horizontal-container flex-1 h-gap-xs">
                                        <Input placeholder="Country" onWhite={false} flex={true} onChange={(e) => setCountry(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="product-vertical-container pad-m v-gap-s no-pad-top">
                            <ReCAPTCHA
                                sitekey={sitekey} 
                                onChange={handleCaptchaChange} />
                        </div>
                        <Divider vertical={false} />
                        <div className="product-vertical-container space-between pad-m v-gap-m white-bg">
                            <div className="product-horizontal-container h-gap-s space-between">
                                <Label content="Total:" bold={true} size="m" mono={true} />
                                <Label content={formatToCurrency(items.reduce((acc, cartItem) => acc + (cartItem.product.price * cartItem.quantity), 0))} size="m" mono={true} />
                            </div>
                            <ChipRounded label="No Payment Required at this time" positive={true} flex={true} center={true} />
                            <Button label="Submit Order Request" brand={true} flex={true} large={true} disabled={!submitEnabled} onClick={handleOrderRequest} />
                            <Label content="Please check your email for confirmation of your order request." size="xs" bold={true} displayFont={true} secondary={true} center={true} />
                        </div>
                        <SimpleLoader loading={temporary.submittingOffer} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;