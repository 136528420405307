import './style/Store.css';
import React, { useEffect, useState } from 'react';
import store from './store.json'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StoreHeader from '../component/store/StoreHeader';
import StoreSidebar from '../component/store/StoreSidebar';
import Toast from '../component/block/Toast';
import StoreContent from '../component/store/StoreContent';
import { setCart } from '../redux/localSlice';

const Store = () => {
    const dispatch = useDispatch();
    const local = useSelector((state) => state.local);

    const products = store;
    const [storeProducts, setStoreProducts] = useState(products);
    const [currentProducts, setCurrentProducts] = useState(products);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [currentProduct, setCurrentProduct] = useState(null);
    const [showCart, setShowCart] = useState(false);
    const [categories, setCategories] = useState(null);
    const [categoryPaths, setCategoryPaths] = useState([]);
    const [currentCategories, setCurrentCategories] = useState([]);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const handleViewProduct = (product) => {
        setCurrentProduct(product);
        navigate(`${encodeURIComponent(product.marketManufacturer?.toLowerCase())}/${encodeURIComponent(product.marketMpn?.toLowerCase())}`);
    };

    useEffect(() => {
        if (!categories) {
            const categoryStructure = [];
            const categoryMap = {};

            products.forEach((product) => {
                const category = product.category;
                if (category) {
                    const categoryPath = product.categoryPath;

                    if (categoryPath) {
                        // Change delimiter from ' > ' to '/'
                        const categoryPathArray = categoryPath.split('/').filter(Boolean); // Use filter(Boolean) to remove empty strings

                        let currentCategory = categoryStructure;
                        categoryPathArray.forEach((categoryName, index) => {
                            // Use an identifier to distinguish, for example, using full path segment
                            const identifier = categoryPathArray.slice(0, index + 1).join('/');

                            if (!categoryMap[identifier]) {
                                categoryMap[identifier] = {
                                    name: categoryName, 
                                    children: []
                                };
                                currentCategory.push(categoryMap[identifier]);
                            }

                            currentCategory = categoryMap[identifier].children;
                        });

                        // Assuming you want to attach the product to its final category node
                        currentCategory.push({
                            ...product, 
                            name: product.category // Add product name if necessary
                        });
                    }
                }
            });

            setCategories(categoryStructure);
            setCurrentCategories(categoryStructure);

            setStoreProducts(products);
        }
    }, [products]);

    useEffect(() => {
        const categoryPath = pathname.startsWith("/") ? pathname.substring(1) : pathname;

        if (categoryPath?.includes("cart")) {
            setShowCart(true)
        } else {
            setShowCart(false)
        }

        if (categoryPath?.length > 0 && categories?.length > 0) {
            const newProducts = storeProducts?.filter((product) => product.categoryPath?.includes(categoryPath));
            setCurrentProducts(newProducts);

            const categoryPaths = categoryPath.split('/').filter(segment => segment.length > 0);

            const possibleProduct = storeProducts?.find((product) => encodeURIComponent(product.marketManufacturer?.toLowerCase()) === categoryPaths[0] && encodeURIComponent(product.marketMpn?.toLowerCase()) === categoryPaths[1]);
            if (possibleProduct && categoryPaths?.length === 2) {
                setCurrentProduct(possibleProduct);
            } else {
                setCurrentProduct(null);
            }

            let currentCategoryParent = categories;
            categoryPaths.forEach((categoryPath) => {
                currentCategoryParent = currentCategoryParent?.find((category) => category.name === categoryPath)?.children;
            });
            
            setCurrentCategories(currentCategoryParent);
            setCategoryPaths(categoryPaths);
        } else if (categoryPath?.length === 0) {
            setCurrentProducts(storeProducts);
            setCurrentCategories(categories);
            setCurrentProduct(null);
            setCategoryPaths([]);
        }
        setSearchInputValue('');
    }, [pathname, categories]);

    const handleCategoryChange = (category) => {
        const rootPath = (!pathname || pathname === "/") ? "" : pathname;
        navigate(`${rootPath}/${category.name}`);
        if (category?.children?.length > 0) {
            setCurrentCategories(category?.children);
        }
        setCurrentProduct(null);
    }

    const handleCategoryChangeNew = (categoryName) => {
        const rootPath = (!pathname || pathname === "/") ? "/" : pathname;

        if (rootPath.includes(categoryName)) {
            // strip everything after the category name
            navigate(`${rootPath.substring(0, rootPath.indexOf(categoryName))}`);
        }
    }

    const addToCart = (product, quantity) => {
        const existingCartItem = local.cart.find((cartItem) => cartItem.product.marketMpn === product.marketMpn && cartItem.product.marketManufacturer === product.marketManufacturer);
        if (existingCartItem) {
            const newCart = local.cart.map((cartItem) => {
                if (cartItem.product.marketMpn === product.marketMpn && cartItem.product.marketManufacturer === product.marketManufacturer) {
                    return {
                        product: cartItem.product,
                        quantity: cartItem.quantity + quantity
                    };
                }
                return cartItem;
            });
            dispatch(setCart(newCart));
            return;
        } else {
            dispatch(setCart([...local.cart, { product, quantity }]));
        }
    };

    useEffect(() => {
        if (showSuccessToast) {
            setTimeout(() => {
                setShowSuccessToast(false);
            }, 6000);
        }
    }, [showSuccessToast]);

    return (
        <div id="store" className="fill-available product-vertical-container relative">
            <StoreHeader
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                cart={local.cart} />
            <div className="product-vertical-container flex-1">
                <div className="product-horizontal-container flex-1 max-100vh">
                    <StoreSidebar
                        currentCategories={currentCategories}
                        categoryPaths={categoryPaths}
                        handleCategoryChange={handleCategoryChange}
                        handleCategoryChangeNew={handleCategoryChangeNew} />
                    <StoreContent
                        showCart={showCart}
                        cart={local.cart}
                        handleViewProduct={handleViewProduct}
                        currentProducts={currentProducts}
                        handleCategoryChangeNew={handleCategoryChangeNew}
                        categoryPaths={categoryPaths}
                        currentProduct={currentProduct}
                        addToCart={addToCart}
                        searchInputValue={searchInputValue}
                        setShowSuccessToast={setShowSuccessToast} />
                </div>
            </div>
            <div id="keystone-user-toasts-wrapper">
                <div className="product-vertical-container flex-1 width-100 v-gap-s pad-l align-center">
                    {showSuccessToast && <Toast message="Order Request Submitted Successfully" type="user" state="positive" />}
                </div>
            </div>
        </div>
    );
};

export default Store;