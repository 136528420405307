import './style/InfoBlock.css';
import React, { useState, useEffect } from 'react';
import { } from '../../redux/localSlice';
import { formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';
import Label from './Label';
import ChipRounded from './ChipRounded';

const InfoBlock = (
    {
        index,
        primaryLabel,
        primaryFormat = "text",
        primaryMono = false,
        secondaryLabel,
        secondaryLabelSingleLine,
        primaryLabelAlternate,
        secondaryLabelAlternate,
        showAlternate = false,
        flex,
        processing = false,
        alignRight = false,
        padRight = false,
        padLeft = false,
        hiddenOnMobile = false,
        hidden = false,
        card = false,
        inline = false,
        tag = {
            label: "",
            secondary: false,
            format: "text",
            tooltip: false,
            tooltipIcon: null,
            tooltipContent: null
        },
        tags = null,
    }
) => {
    const [label, setLabel] = useState(null);
    const [alternateLabel, setAlternateLabel] = useState(null);

    useEffect(() => {
        if (label === "") {
            return;
        }
        setLabel(
            primaryFormat === "usd-extended" ? formatToCurrencyExpanded(label) :
            primaryFormat === "usd" ? formatToCurrency(label) : label
        );
    }, [label, primaryFormat]);

    useEffect(() => {
        setLabel(showAlternate ? primaryLabelAlternate : primaryLabel);
        setAlternateLabel(showAlternate ? secondaryLabelAlternate : secondaryLabel);
    }, [primaryLabel, secondaryLabel, primaryLabelAlternate, secondaryLabelAlternate, showAlternate]);

    return (
        <div key={index ? index : null} className={`keystone-info-block product-horizontal-container v-gap-xxs ${flex ? "flex-1" : "min-160"} ${alignRight === true ? "justify-end" : ""} ${padRight === true ? "pad-right-24" : ""} ${padLeft === true ? "pad-left-24" : ""} ${hiddenOnMobile === true ? "mobile-hide" : ""} ${hidden === true ? "hide" : ""} ${card === true ? "keystone-card pad-xs" : ""}`}>
            {!processing && (
                <div className={`keystone-info-block ${inline ? "product-horizontal-container width-100 space-between" : "product-vertical-container"} v-gap-xxs ${alignRight === true ? "right-align" : ""}`}>
                    <div className={`product-horizontal-container align-center h-gap-xs ${alignRight ? "justify-end" : ""}`}>
                        {label && <Label content={label || ""} size="s" bold={true} mono={primaryMono} displayFont={!primaryMono} />}
                        {(tags && tags?.length > 0) && tags?.map((tag, index) => {
                            if (tag && tag?.label !== "") {
                                return <ChipRounded key={index} index={index} label={tag.format === "usd" ? formatToCurrency(tag.label) : tag.label} positive={!tag?.secondary} secondary={tag?.secondary} small={true} />;
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    {alternateLabel && <Label content={alternateLabel} size="s" mono={true} singleLine={secondaryLabelSingleLine} />}
                </div>
            )}
            {processing && (
                <div className={`keystone-info-block product-vertical-container v-gap-xxs`}>
                    <Label content="Processing" size="s" bold={true} mono={primaryMono} secondary={true} />
                </div>
            )}
        </div>
    );
};

export default InfoBlock;