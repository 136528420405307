import './style/LabelInput.css';
import React, { useState, useRef } from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';
import Input from './Input';

const LabelInput = ({ onChange, index, type = "text", placeholder = "", label, value, valueType }) => {

    const inputRef = useRef(null);
    const [results, setResults] = useState([]);

    const handleBlur = () => {
        setResults([]);
    };

    const handleChange = (e) => {
        if (valueType === "location-search" && inputRef?.current?.value) {
            setResults([inputRef?.current?.value]);
        } else {
            onChange(e);
        }
    };

    return (
        <div key={index} className="keystone-label-input product-vertical-container v-gap-xs flex-1 relative">
            <Label content={label} size="s" bold="true" displayFont={true} />
            <Input placeholder={placeholder} type={type} inputRef={inputRef} large={true} value={value} onWhite={false} onChange={(e) => handleChange(e)} onFocus={handleChange} onBlur={handleBlur} />
            {results?.length > 0 && <div className="keystone-label-input-results keystone-card pad-s">
                {results.map((result, index) => (
                    <Label key={index} index={index} content={result} size="m" mono={true} />
                ))}
            </div>}
        </div>
    );
};

export default LabelInput;