import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080' : 'https://shop.trymaterialize.com';
};

export const submitOffer = createAsyncThunk(
    'store/submitOffer',
    async ({ offer }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/store/submit_offer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(offer)
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const temporarySlice = createSlice({
    name: 'temporary',
    initialState: {
        submittedOffers: [],
        errorSubmittingOffer: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(submitOffer.pending, (state) => {
            state.submittingOffer = true;
            state.errorSubmittingOffer = null;
        })
        .addCase(submitOffer.fulfilled, (state, action) => {
            state.submittingOffer = false;
            state.submittedOffers.push(action.payload);
        })
        .addCase(submitOffer.rejected, (state, action) => {
            state.submittingOffer = false;
            state.errorSubmittingOffer = action.payload;
        });
    }
});

export default temporarySlice.reducer;