import './style/Input.css';
import React from 'react';

const Input = ({ placeholder, type = "text", width100 = false, value, onChange, inputRef, flex, large = false, onBrand, onWhite = true, alignRight = false, onFocus = null, onBlur = null }) => {
    return (
        <input type={type} className={"keystone-input sf-mono size-m" + (flex ? " flex-1" : "") + (alignRight ? " align-right" : "") + (large ? " large" : " small") + (onBrand ? " on-brand" : "") + (onWhite ? " on-white" : "") + (width100 ? " width-100" : "")} placeholder={placeholder} onChange={onChange} onFocus={onFocus} onBlur={onBlur} value={value} ref={inputRef}>
        </input>
    );
};

export default Input;