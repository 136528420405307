import './style/Product.css';
import React, { useEffect, useState } from 'react';
import boxGreen from '../media/icon_box_green.png'
import Label from '../component/block/Label';
import InfoBlock from '../component/block/InfoBlock';
import ChipRounded from '../component/block/ChipRounded';
import Button from '../component/block/Button';
import LabelInput from '../component/block/LabelInput';
import Divider from '../component/block/Divider';
import CollapsableSection from '../component/block/CollapsableSection';
import { formatToCurrencyExpanded, formatPercentWithCommas, formatNumberWithCommas } from '../util/Calculate';
import { useSelector } from 'react-redux';

const Product = ({ currentProduct, addToCart }) => {
    const local = useSelector((state) => state.local);
    const [quantity, setQuantity] = useState(1);
    const handleQuantityInputChange = (e) => {
        const value = e.target.value;
        // Use a regex to ensure all input is numeric
        if (/^\d+$/.test(value) && parseInt(value) <= currentProduct?.quantity) {
            setQuantity(value);
        } else if (value === '') {
            // Allow empty input to reset the field
            setQuantity('');
        }
    };

    const handleBuyNow = () => {
        addToCart(currentProduct, quantity);
    };

    // const handleBuyNow = async () => {
    //         // The data you want to send in the request body
    //     const requestData = {
    //         // Add your request data here
    //         // For example, you may need to send an amount, currency, or other metadata required by your backend
    //         amount: 1000,
    //         currency: 'usd',
    //         // any additional fields
    //     };
        
    //     try {
    //         // Perform the POST request
    //         const response = await fetch('http://localhost:8080/stripe/create_checkout_session', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(requestData)
    //         });

    //         // Check if the request was successful
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         // Parse the response body
    //         const data = await response.json();

    //         // Handle the session ID or other response data as needed
    //         console.log('Checkout session data:', data);

    //         // If your backend returns a URL for Stripe Checkout, you can redirect the user to it:
    //         if (data.url) {
    //             window.location.href = data.url;
    //         }

    //     } catch (error) {
    //         // Handle errors here
    //         console.error('There was a problem with the checkout session request:', error);
    //     }
    // };

    const addToQuantity = (amount) => {
        const newQuantity = parseInt(quantity, 10) + amount;
        if (newQuantity > 0 && newQuantity <= currentProduct?.quantity) {
            setQuantity(newQuantity);
        } else if (newQuantity > currentProduct?.quantity) {
            setQuantity(currentProduct?.quantity);
        }
    };

    return (
        <div className="product-vertical-container mobile-vertical flex-1 v-gap-xs width-100 h-gap-s v-gap-s">
            <div className="product-vertical-container flex-1 keystone-card-secondary">
                <div className="product-horizontal-container mobile-vertical flex-1 white-bg">
                    <div className="product-vertical-container flex-2 v-gap-xs pad-l">
                        <div className="product-vertical-container v-gap-xxs">
                            <Label content={currentProduct?.marketManufacturer} bold={true} mono={true} size="xs" secondary={true} uppercase={true} />
                            <Label content={currentProduct?.marketMpn} mono={true} size="display" uppercase={true} />
                        </div>
                        <InfoBlock tags={[
                            currentProduct?.specifications?.some(spec => spec.name === "Case/Package") && {
                                label: currentProduct?.specifications?.find(spec => spec.name === "Case/Package")?.value,
                                secondary: true,
                            },
                            currentProduct?.aspects?.rohs_compliant && {
                                label: "RoHS",
                                secondary: true,
                            },
                            currentProduct?.aspects?.lead_free && {
                                label: "Lead Free",
                                secondary: true,
                            }
                        ]}/>
                        <Label content={currentProduct?.description} size="m" mono={true} />
                    </div>
                    <Divider vertical={true} horizontalMobile={true} />
                    <div className="product-vertical-container justify-center flex-1 v-gap-s pad-l">
                        <div className="product-vertical-container v-gap-xs">
                            <div className="product-vertical-container v-gap-xxs">
                                <Label content={`${formatNumberWithCommas(currentProduct?.quantity - (local.cart?.find(item => item.product?.marketMpn === currentProduct.marketMpn && item.product?.marketManufacturer === currentProduct.marketManufacturer)?.quantity || 0))} Available New In-Box`} size="xs" mono={true} uppercase={true} bold={true} secondary={true} />
                                <Label content={`${formatToCurrencyExpanded(currentProduct?.price)}`} size="display" mono={true} />
                            </div>
                            {currentProduct?.cheaperThanAuthorizedSellers && (
                                <ChipRounded label={currentProduct?.percentOfMarketPrice === 0 ? "Lowest Market Price" : `${formatPercentWithCommas(currentProduct?.percentOfMarketPrice)} Cheaper Than Market`} positive={true} small={true} />
                            )}
                            {/* <ChipRounded label="New In-Box" icon={boxGreen} positive={true} /> */}
                            {currentProduct?.lowestMarketPriceQuantity > 0 && <Label content={`${formatNumberWithCommas(currentProduct?.lowestMarketPriceQuantity)} Available for ${formatToCurrencyExpanded(currentProduct?.lowestMarketPrice)} from Authorized Resellers`} size="xs" displayFont={true} bold="true" secondary={true} />}
                        </div>
                    </div>
                </div>
                <Divider vertical={false} />
                <div className="product-horizontal-container mobile-vertical reverse">
                    <div className="product-vertical-container flex-2 v-gap-m pad-l">
                        <Label content="Specifications" bold={true} size="m" displayFont={true} />
                        <div className="product-vertical-container v-gap-xs">
                            {currentProduct?.specificationsByType && Object.keys(currentProduct?.specificationsByType).map((type, index) => (
                                <CollapsableSection key={index} label={type} content={
                                    <div className="product-vertical-container v-gap-m">
                                        {currentProduct?.specificationsByType[type].map((spec, index) => (
                                            <InfoBlock inline={true} key={index} primaryLabel={spec.name} secondaryLabel={spec.value + spec.units} secondaryLabelSingleLine={true} />
                                        ))}
                                    </div>
                                } />
                            ))}
                        </div>
                    </div>
                    <Divider vertical={true} horizontalMobile={true} />
                    <div id="order-add" className="product-vertical-container pad-l v-gap-m flex-1 mobile-vertical">
                        <Label content="Add to Order" size="m" bold={true} displayFont={true}/>
                        <div className="product-vertical-container v-gap-xs">
                            <LabelInput label="Quantity" onChange={handleQuantityInputChange} value={quantity}  />
                            <div className="product-horizontal-container h-gap-xs">
                                <Button label="+250" secondary={true} onClick={() => addToQuantity(250)} flex={true} />
                                <Button label="+500" secondary={true} onClick={() => addToQuantity(500)} flex={true} />
                                <Button label="+1000" secondary={true} onClick={() => addToQuantity(1000)} flex={true} />
                                <Button label="+All" secondary={true} onClick={() => addToQuantity(currentProduct?.quantity)} flex={true} />
                            </div>
                        </div>
                        {/* <form action="localhost:8080/stripe/create_checkout_session" method="POST"> */}
                        <Button onClick={handleBuyNow} type="submit" label={`Add to Order ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(quantity * currentProduct?.price)}`} brand={true} large={true} flexHorizontal={true} />
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;