import './style/StoreContent.css';
import React,  { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Action from '../block/Action';
import Label from '../block/Label';
import Product from '../../page/Product';
import Row from '../block/Row';
import { formatToCurrency, formatNumberWithCommas, formatPercentWithCommas } from '../../util/Calculate';
import Button from '../block/Button';
import Divider from '../block/Divider';
import LabelInput from '../block/LabelInput';
import Input from '../block/Input';
import ChipRounded from '../block/ChipRounded';
import ReCAPTCHA from "react-google-recaptcha";
import Cart from './Cart';

const StoreContent = (
    { 
        showCart,
        cart,
        handleViewProduct,
        currentProducts,
        handleCategoryChangeNew,
        categoryPaths,
        currentProduct,
        addToCart,
        searchInputValue,
        setShowSuccessToast,
    }
) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
        }
    };

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    const validateFields = () => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    };

    useEffect(() => {
        validateFields();
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    const lowestMarketPriceTemplate = (product) => {
        return product.cheaperThanAuthorizedSellers && {
            label: product.percentOfMarketPrice === 0 ? "Lowest Market Price" : `${formatPercentWithCommas(product.percentOfMarketPrice)} Cheaper Than Market`,
        }
    };

    return (
        <div className="product-vertical-container flex-1 pad-m v-gap-m width-100">
            {currentProduct === null && !showCart && <div className="product-vertical-container v-gap-m pad-l keystone-card">
                <Label content={`${currentProducts?.length} Products`} size="l" bold={true} displayFont={true} />
                <div className="product-horizontal-container h-gap-m mobile-hide">
                    <Action label="All Products" onClick={() => navigate('/')} breadcrumb={true} onWhite={true} active={categoryPaths?.length === 0} />
                    {categoryPaths?.map((categoryPath, index) => (
                        <Action key={categoryPath} index={index} label={categoryPath.replaceAll("-", " ")} onClick={() => handleCategoryChangeNew(categoryPath)} breadcrumb={true} active={index === categoryPaths.length - 1} onWhite={true} />
                    ))}
                </div>
            </div>}
            <div className={`product-vertical-container h-gap-xs ${(!currentProduct && !showCart) ? "keystone-card" : ""} overflow-auto`}>
                {showCart ? (
                    <Cart items={cart} handleViewProduct={handleViewProduct} setShowSuccessToast={setShowSuccessToast} />
                ) : currentProduct ? (
                    <Product currentProduct={currentProduct} addToCart={addToCart} />
                ) : (currentProducts?.filter(product => product?.marketManufacturer?.toLowerCase()?.includes(searchInputValue.toLowerCase()) || product?.marketMpn?.toLowerCase()?.includes(searchInputValue.toLowerCase())).map((product) => (
                    <Row
                        key={product.id}
                        index={product.id}
                        primaryLabel={product.marketMpn}
                        secondaryLabel={product.marketManufacturer}
                        table={true}
                        onClick={() => handleViewProduct(product)}
                        tags={[
                            lowestMarketPriceTemplate(product),
                        ]}
                        infoBlocks={[
                            {
                                tags: [
                                    product?.specifications?.some(spec => spec.name === "Case/Package") && {
                                        label: product?.specifications?.find(spec => spec.name === "Case/Package")?.value,
                                        secondary: true,
                                    },
                                    product?.aspects?.rohs_compliant && {
                                        label: "RoHS",
                                        secondary: true,
                                    },
                                    product?.aspects?.lead_free && {
                                        label: "Lead Free",
                                        secondary: true,
                                    }
                                ]
                            },
                            {
                                primaryLabel: "Unit Price",
                                secondaryLabel: formatToCurrency(product?.price),
                            },
                            {
                                primaryLabel: "Units Available",
                                secondaryLabel: formatNumberWithCommas(product?.quantity - (cart?.find(item => item.product?.marketMpn === product.marketMpn && item.product?.marketManufacturer === product.marketManufacturer)?.quantity || 0))
                            }
                        ]}
                    />
                )))}
                {(!showCart && !currentProduct && currentProducts?.filter(product => product?.marketManufacturer?.toLowerCase()?.includes(searchInputValue.toLowerCase()) || product?.marketMpn?.toLowerCase()?.includes(searchInputValue.toLowerCase()))?.length === 0) && (
                    <div className="pad-l">
                        <Label content="No products found matching that search" size="s" bold={true} displayFont={true} center={true} secondary={true} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StoreContent;