import Label from './Label';
import './style/Action.css';
import React from 'react';

const Action = ({ index, reference, onClick, label, negative = false, breadcrumb = false, active = false, onWhite = false  }) => {
    return (
        <div ref={reference} key={index} className={`keystone-action ${onWhite ? "on-white" : ""} ${negative ? "negative" : ""} ${breadcrumb ? "breadcrumb" : ""} ${active ? "active" : ""}`} onClick={onClick}>
            <Label content={label} size="s" bold={true} displayFont={true} capitalize={true} />
        </div>
    );
};

export default Action;