import './style/StoreHeader.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import searchIcon from '../../media/search.png'
import cartIcon from '../../media/cart.png'
import Label from '../block/Label';
import Button from '../block/Button';
import logo from '../../media/logo-mtrlz.png'
import AppIcon from '../../media/app-icon.png';
const StoreHeader = ({ searchInputValue, setSearchInputValue, cart }) => {
    const navigate = useNavigate();
    
    const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value);
    };

    const handleShowCart = () => {
        navigate('/cart');
    };
    
    return (
        <div id="store-header" className="product-horizontal-container space-between align-center pad-m">
            <div className="flex-1">
                <button id="product-home-button" onClick={() => navigate('/')}>
                    <img id="product-logo" src={window.screen.width < 768 ? AppIcon : logo} alt="MTRLZ Logo" />
                </button>
            </div>
            <div id="product-search-wrapper">
                <img id="product-search-icon" src={searchIcon} alt="Search Bar Icon" />
                <input id="product-search-input" type="text" placeholder="Search" onChange={handleSearchInputChange} value={searchInputValue}></input>
            </div>
            <div className="flex-1 justify-end">
                <button id="cart-button" className="relative" onClick={handleShowCart}>
                    {cart.length > 0 && <div id="cart-count" className="flex align-center justify-center"><Label content={cart.length} mono={true} size="xs" bold={true} /></div>}
                    <img id="cart-logo" src={cartIcon} alt="Cart Icon" />
                </button>
            </div>
        </div>
    );
};

export default StoreHeader;